import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import * as consumerRoutes from '../../constants/routes';
import Provider from './Providers/Provider';
import AddSocialProvider from './Providers/AddSocialProvider';
import { GOOGLE_PROVIDER, FACEBOOK_PROVIDER, PASSWORD_PROVIDER } from './const';
import ContainerBox from 'components/Common/ContainerBox';

const styleOverride = {};

export const ManageProviders = () => {
  const history = useHistory();

  const [googleProviderData, setGoogleProviderData] = useState(null);
  const [facebookProviderData, setFacebookProviderData] = useState(null);
  const [passwordProviderData, setPasswordProviderData] = useState(null);

  const authUser = useSelector((state) => state.sessionState.authUser);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  useEffect(() => {
    if (!authUser) {
      history.push(consumerRoutes.SIGN_IN);
    } else if (authUser?.providerData) {
      // Load auth providers.
      const googleProvider = authUser.providerData.filter((obj) => {
        return obj.providerId === GOOGLE_PROVIDER;
      });
      const facebookProvider = authUser.providerData.filter((obj) => {
        return obj.providerId === FACEBOOK_PROVIDER;
      });
      const passwordProvider = authUser.providerData.filter((obj) => {
        return obj.providerId === PASSWORD_PROVIDER;
      });
      if (googleProvider?.[0]) {
        setGoogleProviderData(googleProvider[0]);
      }
      if (facebookProvider?.[0]) {
        setFacebookProviderData(facebookProvider[0]);
      }
      if (passwordProvider?.[0]) {
        setPasswordProviderData(passwordProvider[0]);
      }
    }
    // eslint-disable-next-line
	}, [authUser])

  return (
    <ContainerBox>
      <Typography variant="h1" className={classes.title} component="h1">
        Auth providers list
      </Typography>

      <Typography
        variant="body2"
        className={classes.description}
        component="h2"
      >
        Your main email address: {authUser?.email}
      </Typography>
      <ul>
        {passwordProviderData && <Provider {...passwordProviderData} />}
        {googleProviderData ? (
          <Provider {...googleProviderData} />
        ) : (
          <AddSocialProvider type={GOOGLE_PROVIDER} />
        )}
        {facebookProviderData ? (
          <Provider {...facebookProviderData} />
        ) : (
          <AddSocialProvider type={FACEBOOK_PROVIDER} />
        )}
      </ul>
    </ContainerBox>
  );
};
