import * as Sentry from '@sentry/browser';
import { dbRef } from '../firebase/firebase';
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_UPDATE,
  FETCH_ACCOUNT_CREATE,
  FETCH_BRAND_INFO,
  FETCH_CONFIG,
  FETCH_PLANS,
  FETCH_PRIVACY_INFO,
  FETCH_STATIC_TEXT,
  SESSION_DB_UPDATE,
  SET_ERROR_PAGE,
  SET_NOTIFICATION,
} from '../constants/actiontypes';

let veteranListener = () => {};

export const onSetAuthUser = (authUser) => (dispatch) => {
  dispatch({
    type: 'AUTH_USER_SET',
    authUser,
  });

  // If user logs out, reset accountData.
  if (!authUser) {
    veteranListener();
    dispatch({
      type: 'FETCH_ACCOUNT',
      payload: null,
    });
  }
};
// Update/Write session into the Store.
export const updateSession = (updates) => (dispatch) => {
  dispatch({
    type: SESSION_DB_UPDATE,
    payload: updates,
  });
};

export const loadVeteranRecord = async (uid) => {
  return dbRef
    .collection('veterans')
    .doc(uid)
    .get()
    .then((doc) => doc.data());
};

// Get all the fields in the user document from DB.
export const fetchAccountInfo = (uid) => (dispatch) =>
  (veteranListener = dbRef
    .collection('veterans')
    .doc(uid)
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_ACCOUNT,
          payload: doc.data(),
        });
      } else {
        dispatch({
          type: FETCH_ACCOUNT_CREATE,
          payload: true,
        });
      }
    }));

export const updateAccountData = (data) => (dispatch) =>
  dispatch({
    type: FETCH_ACCOUNT_UPDATE,
    payload: data,
  });

// Get brand filtered by brand name,
export const fetchBrandInfo = (providerToken) => (dispatch) => {
  dbRef
    .collection('accessDomain')
    .doc(providerToken)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.exists) {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: false,
        });
      } else {
        return querySnapshot.data().brandUuid;
      }
    })
    .then((brandId) => {
      if (brandId) {
        dbRef
          .collection('nodes_brand')
          .doc(brandId)
          .onSnapshot((querySnapshot) => {
            if (!querySnapshot.exists) {
              dispatch({
                type: FETCH_BRAND_INFO,
                payload: false,
              });
            } else {
              dispatch({
                type: FETCH_BRAND_INFO,
                payload: querySnapshot,
              });
            }
          });
      } else {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: false,
        });
      }
    });
};

/**
 * Get Privacy Policy and
 * Terms of Service data from db.
 *
 * @return {(function(*): void)|*}
 */
export const fetchPrivacyInfo = () => (dispatch) => {
  // Load privacy_policy doc.
  dbRef
    .collection('privacyDocuments')
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        dispatch({
          type: FETCH_PRIVACY_INFO,
          payload: false,
        });
      } else {
        const privacyData = {};
        querySnapshot.docs.map((doc) => {
          privacyData[doc.id] = doc.data();
        });
        dispatch({
          type: FETCH_PRIVACY_INFO,
          payload: privacyData,
        });
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('Error loading privacy data', error.message);
    });
};

// Get all the static texts for the site.
export const fetchStaticTexts = () => (dispatch) => {
  dbRef
    .collection('AuthStaticText')
    .doc('textsWesalute')
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_STATIC_TEXT,
          payload: doc.data(),
        });
      } else {
        dispatch({
          type: FETCH_STATIC_TEXT,
          payload: false,
        });
      }
    });
};

// Get the DB config collection.
export const fetchConfig = () => (dispatch) => {
  dbRef
    .collection('config')
    .doc('va_user')
    .onSnapshot((doc) => {
      if (doc.exists) {
        dispatch({
          type: FETCH_CONFIG,
          payload: doc.data(),
        });
      } else {
        dispatch({
          type: FETCH_CONFIG,
          payload: false,
        });
      }
    });
};

// Get all the avaiable plans.
export const fetchPlans = () => (dispatch) => {
  dbRef
    .collection('nodes_plan')
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        dispatch({
          type: FETCH_PLANS,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_PLANS,
          payload: querySnapshot.docs,
        });
      }
    });
};

// Dispatch the error page.
export const setErrorPage =
  (
    alertTitle,
    alertDesc,
    alertType,
    description,
    buttonTitle,
    buttonPage,
    buttonExternal,
    buttonClose,
    buttonSignOut,
    descriptionTitle
  ) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_PAGE,
      payload: {
        alert: {
          title: alertTitle ? alertTitle : false,
          desc: alertDesc ? alertDesc : false,
          type: alertType ? alertType : false,
        },
        desc: description ? description : false,
        descTitle: descriptionTitle ? descriptionTitle : false,
        btn: {
          title: buttonTitle ? buttonTitle : false,
          page: buttonPage ? buttonPage : false,
          external: buttonExternal ? buttonExternal : false,
          close: buttonClose ? buttonClose : false,
          signout: buttonSignOut ? buttonSignOut : false,
        },
        active: true,
      },
    });
  };

// Clear the error page.
export const clearErrorPage = () => (dispatch) => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      alert: {
        title: false,
        desc: false,
        type: false,
      },
      desc: false,
      descTitle: false,
      btn: {
        title: false,
        page: false,
        external: false,
        close: false,
        signout: false,
      },
      active: false,
    },
  });
};

// Set notification.
export const setNotification = (title, active, type) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: {
      title: title ?? '',
      active: active ?? false,
      type: type ?? 'success',
    },
  });
};

// Get the premium_brands_auth from blocks_premium_brands collection.
export const fetchWesaluteBrands = () => {
  return dbRef
    .collection('blocks_premium_brands')
    .where('machineName', '==', 'premium_brands_auth')
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return null;
      }
      const brandsData = querySnapshot.docs[0].data();

      return {
        title: brandsData?.title,
        brands: brandsData?.brands,
      };
    });
};
