import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import PublicDeviceSwitch from '../../../Common/PublicDeviceSwitch';
import ContainerBox from 'components/Common/ContainerBox';
import * as routes from '../../../../constants/routes';

const styleOverride = {
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '25px',
    marginBottom: '25px',
  },
};

const SignInForm = (props) => {
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const staticTexts = useSelector((state) => state.textsState.data);
  const customStyles = { ...defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  const history = useHistory();

  const {
    onSubmit,
    onChangeInput,
    password,
    passwordType,
    email,
    error,
    isInvalid,
    togglePasswordType,
    inputValidation,
    fieldsError,
  } = props;

  return (
    <ContainerBox>
      <IconButton
        aria-label="back"
        className={classes.back}
        onClick={() => history.push(routes.SIGN_IN)}
      >
        <ArrowBackIosIcon fontSize="medium" />
      </IconButton>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <FormControl
          error={!!fieldsError.email || !!error}
          variant="filled"
          fullWidth
        >
          <InputLabel htmlFor="email">{staticTexts.Email}</InputLabel>
          <FilledInput
            id="email"
            className={classes.field}
            value={email}
            onChange={(e) => onChangeInput(e)}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Email, true, 'email');
            }}
            data-id="signin-email"
          />
          <FormHelperText className={classes.marginHelper}>
            {error ? error.message : fieldsError.email}
          </FormHelperText>
        </FormControl>
        <FormControl
          error={!!fieldsError.password || !!error}
          variant="filled"
          fullWidth
        >
          <InputLabel htmlFor="password">{staticTexts.Password}</InputLabel>
          <FilledInput
            id="password"
            className={classes.field}
            type={passwordType}
            value={password}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.Password, true, 'password');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Password, true, 'password');
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordType}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {passwordType === 'password' ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
            data-id="signin-password-field"
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.password}
          </FormHelperText>
        </FormControl>

        <PublicDeviceSwitch />

        <Button
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          size="large"
          data-id="signin-form-button"
        >
          {'Submit'}
        </Button>

        <Typography variant="body2" component="div">
          <Link
            href={'#'}
            className={classes.link}
            onClick={(e) => {
              e.preventDefault();
              history.push(routes.PASSWORD_FORGET);
            }}
            data-id="password-forget-link"
          >
            {staticTexts.ForgotPasswordLink}
          </Link>
        </Typography>
      </form>
    </ContainerBox>
  );
};

export default SignInForm;
