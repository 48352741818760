import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { analytics, getCustomRoute, helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import { db } from '../../../actions';
import Preloader from '../../Preloader';

import '../../../styles/consent.css';

import { isFullyVerified } from '../../../actions/identification';
import { HYDRA_BRAND_CONNECTIONS_USED } from '../../../constants/hydra';

const Consent = (props) => {
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );

  const useStyles = makeStyles((theme) => ({
    ...defaultFormStyles,
    consentBox: {
      margin: '74px 0',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.slate.main,
      padding: '50px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '24px 24px 48px',
        margin: 0,
      },
    },
    consentHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 24,
    },
    consentHeaderImg: {
      width: '80px',
      paddingRight: '5%',
      '& img': {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
      },
    },
    avatar: {
      height: 52,
      width: '25%',
      paddingLeft: '5%',
      borderLeft: '1px solid #B2BCCA',
      display: 'flex',
      borderRadius: 0,
      '& img': {
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '5%',
      },
      [theme.breakpoints.up('md')]: {
        height: 76,
      },
    },
    consentTitle: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.2,
      margin: '0 0 24px',
      textAlign: 'left',
      color: theme.palette.slate.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
    consentSubTitle: {
      color: theme.palette.slate.main,
      fontSize: '1.125rem',
      lineHeight: 1.61,
      textAlign: 'left',
      marginBottom: 32,
    },
    consentList: {
      padding: 0,
      listStyle: 'none',
      marginBottom: 48,
      [theme.breakpoints.up('md')]: {
        marginBottom: 60,
      },
    },
    consentListItem: {
      marginBottom: 16,
      fontWeight: 700,
      lineHeight: 1.5,
      '& span': {
        display: 'block',
        fontSize: '87.5%',
        color: '#677796',
        fontWeight: 400,
      },
    },
    consentAllow: {
      backgroundColor: theme.palette.slate.main,
      borderRadius: 2,
      padding: 13,
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
      marginBottom: 10,
      '&:hover': {
        backgroundColor: '#677796',
      },
    },
    consentDeny: {
      color: theme.palette.slate.main,
      textTransform: 'none',
      textDecoration: 'underline',
      fontSize: 14,
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }));
  const classes = useStyles();

  const { urlQueryData } = props;

  const { setErrorPage } = db;

  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);

  const [showConsentScreen, setShowConsentScreen] = useState(false);
  const [clientApp, setClientApp] = useState('App');
  const [clientName, setClientName] = useState('');
  const [clientLogo, setClientLogo] = useState('');
  const [upgradeRequired] = useState(false);
  const [verifyRequired, setVerifyRequired] = useState(false);
  const [scope, setScope] = useState([]);

  // Prepare text for Consent screen.
  let consentHeaderText = staticTexts?.HydraConsentHeader
    ? staticTexts?.HydraConsentHeader
    : '@app is requesting access to your:';
  consentHeaderText = consentHeaderText.replace(
    '@app',
    clientName || clientApp
  );

  let upgradeHeaderText = staticTexts?.HydraUpgradeHeaderText
    ? staticTexts?.HydraUpgradeHeaderText
    : 'To redeem this offer you must have a paid Vet Rewards account with WeSalute.';
  let upgradeButtonText = staticTexts?.HydraUpgradeButtonText
    ? staticTexts?.HydraUpgradeButtonText
    : 'Upgrade Now';

  let verifyHeaderText = staticTexts?.HydraVerifyHeaderText
    ? staticTexts?.HydraVerifyHeaderText
    : 'To redeem this offer you must verify your military status.';
  let verifyButtonText = staticTexts?.HydraVerifyButtonText
    ? staticTexts?.HydraVerifyButtonText
    : 'Verify Now';

  const denyAccessText = staticTexts?.HydraDenyAccess
    ? staticTexts?.HydraDenyAccess
    : 'Cancel';
  const allowAccessText = staticTexts?.HydraAllowAccess
    ? staticTexts?.HydraAllowAccess
    : 'Authorize Access';
  const consentError = staticTexts?.HydraConsentError
    ? staticTexts?.HydraConsentError
    : 'Error checking user consent';

  // Protect from unauthorized access.
  useEffect(() => {
    document.body.classList.add('page-consent');

    if (!authUser || !urlQueryData?.consent_challenge) {
      getCustomRoute(history);
    } else {
      // Set Brand Connections flag.
      window.localStorage.setItem(HYDRA_BRAND_CONNECTIONS_USED, 'true');
      analytics.page('Consent');
    }

    return () => document.body.classList.remove('page-consent');
    // eslint-disable-next-line
  }, [])

  // Check consent token.
  useEffect(() => {
    if (urlQueryData?.consent_challenge) {
      // const params = {
      //   clientId: "test",
      //   clientName: "Demo: Amazon is requesting access to your:\n",
      //   upgradeRequired: false,
      //   clientLogo: "https://checkout-dev.veteransadvantage.com/images/brand-connections/amazon-dev.png",
      //   // clientLogo: "https://www.veteransadvantage.com/sites/default/files/dell-logo.png?itok=iASpfrFn",
      //   // clientLogo: "https://www.veteransadvantage.com/sites/default/files/2023-06/att_prepd_hz_rgb_pos.png?itok=-wRagfys",
      //   // clientLogo: "https://checkout-dev.veteransadvantage.com/images/brand-connections/robinhood-dev.png",
      //   // clientLogo: "https://checkout-dev.veteransadvantage.com/images/brand-connections/starbucks-dev.png",
      //   // clientLogo: "https://checkout-dev.veteransadvantage.com/images/brand-connections/bluline-dev.png",
      //   scope: [
      //     {
      //       key: "1",
      //       label: "Full Name, Member ID and Email",
      //       message: "for linking your Veterans Advantage and Demo: Amazon accounts."
      //     },
      //     {
      //       key: "2",
      //       label: "Military Affiliation",
      //       message: "for determining your eligibility for Marketplace content."
      //     },
      //     {
      //       key: "3",
      //       label: "Plan Type (Premium or Free)",
      //       message: "for determining your eligibility for Marketplace content."
      //     },
      //   ]
      // }
      if (accountData) {
        // Prepare parameters to verify consent challenge.
        const requestParams = {
          action: 'process',
          token: urlQueryData?.consent_challenge,
          type: 'load',
          isPrimary: isPrimary(accountData),
        };

        helpers
          .doHydraAction(requestParams)
          .then(({ data: params }) => {
            // Allow only fully verified users.
            if (!isFullyVerified(accountData)) {
              setVerifyRequired(true);
            } else if (params.upgradeRequired && !isPrimary(accountData)) {
              // Send to checkout app for upgrade.
              doUpgrade();
            }

            if (params.consentGrantedPreviously) {
              // Allow and prevent tracking.
              grantAccess('allow', false);
            } else {
              // Allow only fully verified users.
              if (params.clientId) {
                setShowConsentScreen(true);
                setClientApp(params.clientId);

                if (params.clientName) {
                  setClientName(params.clientName);
                }
                if (params.clientLogo) {
                  setClientLogo(params.clientLogo);
                }
                if (params.scope) {
                  setScope(params.scope);
                }
              }
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            dispatch(
              setErrorPage(
                consentError,
                error.message,
                'warning',
                false,
                staticTexts.PreloaderErrorButton,
                routes.SIGN_OUT
              )
            );
          });
      }
    }
  }, [accountData]);

  /**
   * Require users verification if not passed yet.
   */
  useEffect(() => {
    if (accountData) {
      if (clientApp && clientName) {
        // Track Connection Consent Viewed event.
        analytics.track('Connection Consent Viewed', {
          oauthClientId: clientApp,
          oauthClientName: clientName,
          context: {
            app: {
              name: 'AuthApp',
              namespace: 'components/Hydra/Consent',
              build: process.env.REACT_APP_BUILD,
              version: process.env.REACT_APP_VERSION,
            },
          },
          userId: accountData?.memberId ?? null,
        });
      }
    }
  }, [accountData, clientApp, clientName]);

  //Redirect the user to the checkout app
  function doUpgrade() {
    window.location.href =
      helpers.getAppUrl('checkout') +
      `?return_to=${encodeURIComponent(window.location.href)}&iframe=1`;
  }

  /**
   * Check if user is a primary.
   *
   * @param accountData
   * @returns {boolean|undefined|boolean}
   */
  function isPrimary(accountData) {
    return accountData?.roles
      ? accountData?.roles.some((role) => role.indexOf('vr_') !== -1)
      : false;
  }

  //Redirect the user to the verify app
  function doVerify() {
    window.location.href =
      helpers.getAppUrl('verify') +
      `?return_to=${encodeURIComponent(window.location.href)}&iframe=1`;
  }

  // Process access.
  function grantAccess(type, trackAnalytics = true) {
    setShowConsentScreen(false);
    const params = {
      action: 'process',
      token: urlQueryData?.consent_challenge,
      type: type,
      restrictedScope: [],
    };

    // Track only if needed.
    if (trackAnalytics === true) {
      let eventName = 'Connection Consent Accepted';
      if (type === 'deny') {
        eventName = 'Connection Consent Declined';
      }
      analytics.track(eventName, {
        oauthClientId: clientApp,
        oauthClientName: clientName,
        context: {
          app: {
            name: 'AuthApp',
            namespace: 'components/Hydra/Consent',
            build: process.env.REACT_APP_BUILD,
            version: process.env.REACT_APP_VERSION,
          },
        },
        userId: accountData?.memberId ?? null,
      });
    }

    helpers
      .doHydraAction(params)
      .then(({ data: return_to }) => {
        if (return_to) {
          window.location.href = return_to;
        }
      })
      .catch((error) => {
        setShowConsentScreen(true);
        console.error('Processing user consent error', error.message);
        Sentry.captureException(error);
        dispatch(
          setErrorPage(
            consentError,
            error.message,
            'warning',
            false,
            staticTexts.PreloaderErrorButton,
            routes.SIGN_OUT
          )
        );
      });
  }

  if (verifyRequired) {
    return (
      <Box
        p={{ xs: 2, md: 5 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.consentBox}
      >
        <Typography
          variant="h1"
          component="h1"
          className={classes.consentTitle}
        >
          {verifyHeaderText}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => doVerify()}
          data-id="consent-verify-button"
        >
          {verifyButtonText}
        </Button>
      </Box>
    );
  } else if (upgradeRequired) {
    return (
      <Box
        p={{ xs: 2, md: 5 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.consentBox}
      >
        <Typography
          variant="h1"
          component="h1"
          className={classes.consentTitle}
        >
          {upgradeHeaderText}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => doUpgrade()}
          data-id="consent-do-upgrade-button"
        >
          {upgradeButtonText}
        </Button>
      </Box>
    );
  } else if (showConsentScreen) {
    return (
      <Box
        p={{ xs: 2, md: 5 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={classes.consentBox}
      >
        <div className={classes.consentHeader}>
          <div className={classes.consentHeaderImg}>
            <img src={'/assets/images/we-salute-logo.svg'} />
          </div>
          {clientLogo ? (
            <Avatar src={clientLogo} className={classes.avatar} />
          ) : null}
        </div>
        <Typography
          variant="h1"
          component="h1"
          className={classes.consentTitle}
        >
          {consentHeaderText}
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          className={classes.consentList}
        >
          {scope &&
            scope.map((item) => (
              <li key={item?.key} className={classes.consentListItem}>
                {item?.label}
                <span>{item?.message}</span>
              </li>
            ))}
        </Typography>
        <Button
          className={classes.consentAllow}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => grantAccess('allow')}
          data-id="consent-allow-access-button"
        >
          {allowAccessText}
        </Button>
        <Button
          className={classes.consentDeny}
          fullWidth
          onClick={() => grantAccess('deny')}
          data-id="consent-deny-access-button"
        >
          {denyAccessText}
        </Button>
      </Box>
    );
  } else {
    return <Preloader title={staticTexts.PhoneVerificationLoading} />;
  }
};

export default Consent;
