import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import { styles } from '../../styles';
import * as routes from '../../constants/routes';
import { firebase } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { analytics, db, helpers } from '../../actions';

import '../../styles/osano.css';

import Preloader from '../Preloader';
import SignUpForm from '../Sign/SignUpForm';
import SignIn from '../Sign/SignIn';
import EmailVerification from '../Verification/EmailVerification';
import Verify from '../Verification/Verify';
import PasswordForget from '../Sign/PasswordForget';
import PasswordForgetConfirm from '../Sign/PasswordForgetConfirm';
import SignOut from '../Sign/SignOut';
import ContactUs from '../StaticPages/ContactUs';
import Header from '../Common/Header';
import SignInForm from '../Sign/SignIn/SignInForm';
import UserDashboard from '../Sign/UserDashboard';
import SignUpDashboard from '../Sign/SignUpDashboard';
import SignUpWithRedirect from '../Sign/SignUpWithRedirect';
import Consent from '../Hydra/Consent';
import Privacy from '../Privacy';
import VerifyAppVerification from '../Verification/VerifyAppVerification';
import { ManageProviders } from '../ManageProviders';
import { FACEBOOK_PROVIDER, GOOGLE_PROVIDER } from '../ManageProviders/const';
import {
  AddSocialDeepLink,
  DisconnectSocialDeepLink,
} from '../ManageProviders/DeepLink';
import Page404 from 'components/StaticPages/Page404';
import Page401 from 'components/StaticPages/Page401';
import EmailConfirm from '../Sign/EmailConfirm';

const theme = createTheme(styles.theme);
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

import { APPS_DOMAIN, APPS_DOMAIN_OLD } from '../../constants/const';
import { getAllIdentificationStatuses } from '../../actions/identification';
import Notification from 'components/Common/Notification';

const App = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const appDeleted = useRef(false);

  const [urlQueryData, setUrlQueryData] = useState({});
  const [mainErrorPage, setMainErrorPage] = useState(false);
  const [returnType, setReturnType] = useState(null);
  const [source, setSource] = useState('');
  const [allowTests, setAllowTests] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [logo, setLogo] = useState(true);
  const [pageWidth, setPageWidth] = useState('md');

  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountDataCreate = useSelector((state) => state.accountState.create);
  const accountDataStatus = useSelector((state) => state.accountState.status);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTextStatus = useSelector((state) => state.textsState.status);
  const staticTexts = useSelector((state) => state.textsState.data);
  const publicComputer = useSelector(
    (state) => state.sessionDBState.data.publicComputer
  );
  const brandData = useSelector((state) => state.brandState.data);
  const brandDataStatus = useSelector((state) => state.brandState.status);
  const errorPageData = useSelector((state) => state.errorState.active);
  const configData = useSelector((state) => state.configState.config);
  const configDataStatus = useSelector((state) => state.configState.status);
  const remember = useSelector(
    (state) => state.sessionDBState.data.rememberCredentials
  );

  const {
    updateSession,
    fetchBrandInfo,
    fetchPrivacyInfo,
    fetchStaticTexts,
    fetchConfig,
    setErrorPage,
    clearErrorPage,
    onSetAuthUser,
    fetchAccountInfo,
  } = db;

  const { sessionTimeout } = props;

  useEffect(() => {
    // State the version of app.
    if (process.env.REACT_APP_ENV === 'dev') {
      console.log(process.env.REACT_APP_VERSION);
    }

    // Set default styles in local session.
    dispatch(
      updateSession({
        defaultFormStyles: styles.defaultFormStyles,
        defaultDashboardStyles: styles.defaultDashboardStyles,
        defaultAlertStyles: styles.defaultAlertStyles,
        referrer: document.referrer ? document.referrer : null,
      })
    );

    // Segment initialization.
    if (
      window.analytics &&
      (typeof window.analytics.initialized === 'undefined' ||
        !window.analytics.initialized)
    ) {
      const segmentKey = process.env.REACT_APP_SEGMENTKEY;
      analytics.load(segmentKey);
    }

    // Process required query params.
    let urlQuery = {};
    const urlQueryParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlQueryParams.entries()) {
      urlQuery[key] = value;
    }

    // If we have providerToken, fetch brand info.
    if (
      typeof urlQuery.providerToken !== 'undefined' &&
      urlQuery.providerToken !== 'vaplatformdefault'
    ) {
      dispatch(fetchBrandInfo(urlQuery.providerToken));
    }

    if (
      process.env.REACT_APP_ENV !== 'local-dev' &&
      process.env.REACT_APP_ENV !== 'emulator' &&
      typeof urlQuery.return_to !== 'undefined'
    ) {
      try {
        const redirectUrl = new URL(urlQuery.return_to);
        // Remove url redirect which is not a subdomain of VA.
        if (
          redirectUrl.host &&
          !redirectUrl.host.endsWith(`.${APPS_DOMAIN_OLD}`) &&
          !redirectUrl.host.endsWith(`.${APPS_DOMAIN}`) &&
          process.env.REACT_APP_ENV !== 'dev'
        ) {
          delete urlQuery.return_to;
        }
      } catch (e) {
        // Unset return_to if non valid url supplied.
        delete urlQuery.return_to;
      }
    }

    // Set return type and source.
    setReturnType(
      typeof urlQuery.providerToken !== 'undefined' &&
        urlQuery.providerToken === 'vaplatformdefault'
        ? 'verify'
        : typeof urlQuery.login_challenge !== 'undefined'
          ? 'hydra'
          : typeof urlQuery.sessionId !== 'undefined'
            ? 'widget'
            : typeof urlQuery.return_to !== 'undefined'
              ? 'platform'
              : null
    );

    setSource(
      typeof urlQuery.providerToken !== 'undefined' &&
        urlQuery.providerToken === 'vaplatformdefault'
        ? 'VerifyApp'
        : typeof urlQuery.sessionId !== 'undefined'
          ? 'MilVetID'
          : typeof urlQuery.brand_id !== 'undefined'
            ? 'VAZendesk'
            : 'VAPlatform'
    );

    // Set publicComputer flag.
    dispatch(
      updateSession({
        publicComputer: Boolean(urlQuery.publicComputer),
      })
    );

    // Set Allow tests flag.
    if (
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'stage'
    ) {
      setAllowTests(Boolean(urlQuery.allowTests));
    }

    setUrlQueryData(urlQuery);

    if (urlQuery) {
      dispatch(
        updateSession({
          urlQuery: urlQuery,
          hash: window?.location?.hash,
        })
      );
    }

    // Get all the static texts.
    dispatch(fetchStaticTexts());

    // Get the specific settings of the app.
    dispatch(fetchConfig());

    // Get privacy info.
    dispatch(fetchPrivacyInfo());

    // If flag is set, sign out user.
    onAuthStateChanged(getAuth(), (authUserRef) => {
      if (authUserRef) {
        dispatch(onSetAuthUser(authUserRef));
        dispatch(fetchAccountInfo(authUserRef.uid));
      } else {
        dispatch(onSetAuthUser(null));
      }
    });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const unloadCallback = () => {
      if (!appDeleted.current) {
        appDeleted.current = true;
        return firebase.firebase.app().delete();
      }
    };
    window.addEventListener('beforeunload', unloadCallback);
    return async () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    // Hide header on specific pages.
    if (location?.pathname === routes.HYDRA_CONSENT) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    // Dynamic content width based on route.
    if (location?.pathname === routes.PRIVACY) {
      setPageWidth('lg');
    }
    if (location?.pathname === routes.HYDRA_CONSENT) {
      setPageWidth('mid');
    } else if (location?.pathname === routes.VERIFY_APP_VERIFICATION) {
      setPageWidth('lg');
    } else {
      setPageWidth('md');
    }
  }, [location]);

  // Show the business header on business pages.
  useEffect(() => {
    setLogo('/assets/images/we-salute-logo.svg');
  }, []);

  const trySetPersistence = async () => {
    try {
      await firebase.authRef.setPersistence(
        typeof publicComputer === 'undefined' && publicComputer
          ? firebase.persistenceNone
          : remember
            ? firebase.persistenceLocal
            : firebase.persistenceSession
      );
    } catch (error) {
      dispatch(
        updateSession({
          publicComputer: true,
          remember: false,
        })
      );
      Sentry.captureException(error);
      console.error(error);
    }
  };

  useEffect(() => {
    trySetPersistence();
    // eslint-disable-next-line
  }, [remember, publicComputer])

  useEffect(() => {
    if (
      accountDataCreate &&
      authUser &&
      authUser.metadata.creationTime !== authUser.metadata.lastSignInTime &&
      typeof authUser.displayName !== 'undefined' &&
      authUser.displayName &&
      authUser.displayName !== '' &&
      authUser.email
    ) {
      helpers.doCreateUser({
        firstname: authUser.displayName.split(' ').slice(0, -1).join(' '),
        lastname: authUser.displayName.split(' ').slice(-1).join(' '),
        email: authUser.email.toLowerCase(),
      });
    }
  }, [accountDataCreate, authUser]);

  useEffect(() => {
    // Set return type to local session.
    dispatch(
      updateSession({
        returnType: returnType,
      })
    );
    // eslint-disable-next-line
  }, [returnType])

  // Check Identification statuses and show or hide the revoked alert.
  useEffect(() => {
    if (
      accountDataStatus === 'loaded' &&
      staticTextStatus === 'loaded' &&
      configDataStatus === 'loaded'
    ) {
      const { militaryStatus, identityStatus } =
        getAllIdentificationStatuses(accountData);

      if (militaryStatus === 'revoked' || identityStatus === 'revoked') {
        setMainErrorPage(true);
        dispatch(
          setErrorPage(
            staticTexts.ErrorSuspendedAccount,
            staticTexts.ErrorSuspendedAccountDescpiption,
            'error',
            false,
            staticTexts.ErrorSuspendedAccountButton,
            routes.SIGN_OUT
          )
        );
      } else {
        dispatch(clearErrorPage());
        setMainErrorPage(false);
      }
    }
  }, [accountDataStatus, staticTextStatus, configDataStatus, accountData]);

  const checkMaintenance = (initialCheck) => {
    if (
      typeof configData !== 'undefined' &&
      typeof configData.maintenance !== 'undefined' &&
      configData.maintenance.status
    ) {
      dispatch(
        setErrorPage(
          configData.maintenance.title
            ? configData.maintenance.title
            : 'Maintenance',
          configData.maintenance.description
            ? configData.maintenance.description
            : 'We are currently performing scheduled maintenance operations. Functionality is currently limited. Thank you for your patience and please check back soon.',
          'error'
        )
      );
    } else if (
      !isIE &&
      typeof configData !== 'undefined' &&
      typeof configData.maintenance !== 'undefined' &&
      !configData.maintenance.status &&
      !initialCheck
    ) {
      dispatch(clearErrorPage());
    }
  };

  useEffect(() => {
    if (configDataStatus === 'loaded') {
      checkMaintenance(true);
    }

    if (
      typeof configData !== 'undefined' &&
      typeof configData.maintenance !== 'undefined' &&
      typeof configData.maintenance.status !== 'undefined'
    ) {
      checkMaintenance();
    }
    // eslint-disable-next-line
  }, [configDataStatus, configData])

  useEffect(() => {
    if (isIE) {
      dispatch(
        setErrorPage(
          false,
          false,
          'error',
          'To enjoy our website and access the best military discounts, use @chrome @safari, or @mozilla.',
          false,
          false,
          false,
          false,
          false,
          'Wait! Did you know that your browser is likely out of date?'
        )
      );
    }
    // eslint-disable-next-line
  }, [isIE, errorPageData])

  useEffect(() => {
    if (accountData?.memberId) {
      Sentry.setUser({ id: accountData?.memberId });
    }
  }, [accountData]);

  // Inject Osano script.
  useEffect(() => {
    const exists = document.getElementById('osano-script');
    if (!exists) {
      const script = document.createElement('script');
      script.id = 'osano-script';
      script.src = `https://cmp.osano.com/${process.env.REACT_APP_OSANOKEY}/osano.js`;
      const scriptNodes = document.head.getElementsByTagName('script');
      const scriptNode = scriptNodes.length ? scriptNodes[0] : null;
      if (scriptNode) {
        scriptNode.parentNode.insertBefore(script, scriptNode);
      } else {
        document.head.insertBefore(script, null);
      }
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  if (
    staticTextStatus === 'loading' ||
    configDataStatus === 'loading' ||
    (returnType === 'widget' &&
      brandDataStatus === 'loading' &&
      typeof urlQueryData.providerToken !== 'undefined')
  ) {
    return (
      // If the info is not loaded, show preloader.
      <>
        <CssBaseline />
        <Preloader title="Loading app. Please wait." />
      </>
    );
  } else {
    // Set all the routes after the info is loaded.
    // Note: we are passing just the info that is needed, and filter the static data manually.
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {showHeader && (
              <Header
                logo={logo}
                brandData={brandData ? brandData.data() : null}
              />
            )}
            <Notification />
            <Container
              fixed
              maxWidth={pageWidth}
              className={'app'}
              disableGutters
            >
              {errorPageData && <ContactUs />}
              <Switch>
                <Route
                  exact
                  path={routes.SIGN_UP}
                  render={() => <SignUpDashboard />}
                />
                <Route
                  exact
                  path={routes.SIGNUP_WITH_REDIRECT}
                  render={() => <SignUpWithRedirect />}
                />
                <Route
                  exact
                  path={routes.SIGN_UP_EMAIL}
                  render={() => <SignUpForm />}
                />
                <Route
                  exact
                  path={routes.PASSWORD_FORGET}
                  render={() => <PasswordForget />}
                />
                <Route
                  exact
                  path={routes.PASSWORD_FORGET_RESET}
                  render={() => <PasswordForgetConfirm />}
                />
                <Route exact path={routes.SIGN_IN} render={() => <SignIn />} />
                <Route
                  exact
                  path={routes.SIGN_IN_EMAIL}
                  render={() => <SignInForm />}
                />
                <Route
                  exact
                  path={routes.PRIVACY}
                  render={() => (
                    <Privacy allowTests={allowTests} newUser={false} />
                  )}
                />
                <Route
                  exact
                  path={routes.PRIVACY_REGISTER}
                  render={() => (
                    <Privacy allowTests={allowTests} newUser={true} />
                  )}
                />
                <Route
                  exact
                  path={routes.CONTACT_US}
                  render={() => <ContactUs />}
                />
                <Route
                  exact
                  path={routes.SIGN_OUT}
                  render={() => <SignOut />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS}
                  render={() => <ManageProviders />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_FACEBOOK_DEEPLINK}
                  render={() => <AddSocialDeepLink type={FACEBOOK_PROVIDER} />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_GOOGLE_DEEPLINK}
                  render={() => <AddSocialDeepLink type={GOOGLE_PROVIDER} />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_FACEBOOK_DISCONNECT_DEEPLINK}
                  render={() => (
                    <DisconnectSocialDeepLink type={FACEBOOK_PROVIDER} />
                  )}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_GOOGLE_DISCONNECT_DEEPLINK}
                  render={() => (
                    <DisconnectSocialDeepLink type={GOOGLE_PROVIDER} />
                  )}
                />
                {/* Match all the pages below this Route to Page401 if there is a mainErrorPage */}
                {mainErrorPage && <Route render={() => <Page401 />} />}
                {/* Those Routes below will match only is mainErrorPage is false */}
                <Route
                  exact
                  path={routes.USER_DASHBOARD}
                  render={() => <UserDashboard />}
                />
                <Route
                  exact
                  path={routes.EMAIL_VERIFICATION}
                  render={() => <EmailVerification />}
                />
                <Route
                  exact
                  path={routes.VERIFY}
                  render={() => (
                    <Verify
                      sessionTimeout={
                        typeof urlQueryData.sessionTimeout !== 'undefined'
                          ? urlQueryData.sessionTimeout
                          : parseInt(sessionTimeout, 10)
                      }
                      sessionDbId={
                        typeof urlQueryData.sessionId !== 'undefined'
                          ? urlQueryData.sessionId
                          : null
                      }
                      providerToken={
                        typeof urlQueryData.providerToken !== 'undefined' &&
                        urlQueryData.providerToken !== 'vaplatformdefault'
                          ? urlQueryData.providerToken
                          : null
                      }
                      returnUrl={
                        typeof urlQueryData.return_to !== 'undefined'
                          ? urlQueryData.return_to
                          : configData.paltformUrl
                      }
                      hydraChallenge={
                        urlQueryData.login_challenge
                          ? urlQueryData.login_challenge
                          : null
                      }
                      returnType={returnType}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.HYDRA_CONSENT}
                  render={() => <Consent urlQueryData={urlQueryData} />}
                />
                <Route
                  exact
                  path={routes.VERIFY_APP_VERIFICATION}
                  render={() => (
                    <VerifyAppVerification
                      returnType={returnType}
                      source={source}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.EMAIL_CONFIRM}
                  render={() => <EmailConfirm />}
                />
                {/* Last Route in Switch is used for generic 404 page */}
                <Route render={() => <Page404 />} />
              </Switch>
            </Container>
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
};

App.defaultProps = {
  sessionTimeout: '180000',
};

export default App;
