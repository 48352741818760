import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { analytics, api, auth, db, getCustomRoute } from '../../../../actions';
import * as routes from '../../../../constants/routes';
import Preloader from '../../../Preloader';

const EmailConfirmForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const params = useSelector((state) => state.sessionDBState.data.urlQuery);
  const staticTexts = useSelector((state) => state.textsState.data);
  const [loading, setLoading] = useState(true);

  /**
   * Login user if needed.
   */
  useEffect(() => {
    if (params.signin && !authUser) {
      // Sign in user with customToken.
      auth.doSignInWithCustomToken(params.signin).catch((error) => {
        console.error('Error login user', error.message);
        Sentry.captureException(error);
        history.push(routes.SIGN_OUT);
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (params.token && authUser) {
      api
        .confirmEmail(params.token)
        .then(() => {
          setLoading(false);
          dispatch(db.fetchAccountInfo(authUser.uid));
          analytics.track('Email Verification Verified', {
            email: authUser?.email,
          });
        })
        .catch((error) => {
          console.log(`error message: ${error.message}`, error);
          Sentry.captureException(error);
          history.push(routes.SIGN_OUT);
        });
    } else {
      console.log('Empty token parameter');
    }
  }, [authUser]);

  useEffect(() => {
    if (loading === false && accountData?.emailVerified) {
      getCustomRoute(history);
    }
  }, [loading, accountData]);

  return <Preloader title={staticTexts.NewPasswordLoading} />;
};

export default EmailConfirmForm;
