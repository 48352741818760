import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import reactStringReplace from 'react-string-replace';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

const EmailDialog = (props) => {
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const defaultAlertStyles = useSelector(
    (state) => state.sessionDBState.data.defaultAlertStyles
  );
  const staticTexts = useSelector((state) => state.textsState.data);
  const customStyles = { ...defaultFormStyles, ...defaultAlertStyles };
  const useStyles = makeStyles((theme) => ({
    ...customStyles,
    alert: {
      marginBottom: '20px',
    },
    logoutLink: {
      marginTop: '10px',
      textAlign: 'center',
      fontSize: '12px',
      display: 'block',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontStyle: 'normal',
      textDecoration: 'none',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    },
  }));
  const classes = useStyles();

  const {
    authUserEmail,
    showEmailForm,
    onSubmit,
    onChangeInput,
    firstname,
    lastname,
    email,
    inputValidation,
    fieldsError,
    isInvalid,
    signLink,
    emailValidationInProgress,
    isNewUser,
  } = props;

  let emailError = reactStringReplace(
    fieldsError.email,
    '@signin',
    (match, i) => (
      <Link
        key={i}
        href={'#'}
        className={classes.link}
        onClick={signLink}
        data-id="existing-account-link"
      >
        {staticTexts.EmailVerificationExistingAccountLink}
      </Link>
    )
  );

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={showEmailForm}>
      <div className={classes.container}>
        <Alert severity={'info'} className={classes.alert}>
          <AlertTitle className={classes.alertTitle}>
            {staticTexts.EmailDialogTitle}
          </AlertTitle>
          <Typography gutterBottom>
            {staticTexts.EmailDialogDescription}
          </Typography>
        </Alert>

        <FormControl error={!!fieldsError.firstname} variant="filled" fullWidth>
          <InputLabel htmlFor="firstname">{staticTexts.FirstName}</InputLabel>
          <FilledInput
            id="firstname"
            className={classes.field}
            value={firstname}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.FirstName, true, 'firstname');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.FirstName, true, 'firstname');
            }}
            data-id="firstname"
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.firstname}
          </FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.lastname} variant="filled" fullWidth>
          <InputLabel htmlFor="lastname">{staticTexts.LastName}</InputLabel>
          <FilledInput
            id="lastname"
            className={classes.field}
            value={lastname}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.LastName, true, 'lastname');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.LastName, true, 'lastname');
            }}
            data-id="lastname"
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.lastname}
          </FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.email} variant="filled" fullWidth>
          <InputLabel htmlFor="email">{staticTexts.Email}</InputLabel>
          <FilledInput
            id="email"
            className={classes.field}
            disabled={emailValidationInProgress || !!authUserEmail}
            value={email}
            onChange={(e) => onChangeInput(e)}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Email, true, 'email');
            }}
            endAdornment={
              emailValidationInProgress ? (
                <InputAdornment position="end">
                  <CircularProgress />
                </InputAdornment>
              ) : null
            }
            data-id="email"
          />
          <FormHelperText className={classes.marginHelper}>
            {emailError}
          </FormHelperText>
        </FormControl>
        <Button
          onClick={onSubmit}
          disabled={isInvalid}
          className={classes.button}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          variant="contained"
          size="large"
          data-id="email-dialog-button"
        >
          {staticTexts.EmailDialogBtn}
        </Button>
        {/*Show logout link only to new users*/}
        {isNewUser && (
          <Link
            key={43}
            href={'#'}
            className={classes.logoutLink}
            onClick={signLink}
            data-id="logout-link"
          >
            {staticTexts.EmailDialogLogoutLink}
          </Link>
        )}
      </div>
    </Dialog>
  );
};

export default EmailDialog;
