import React, { useState } from 'react';
import {
  linkWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { onSetAuthUser, setErrorPage } from '../../../actions/db';
import Provider from './Provider';
import { GOOGLE_PROVIDER } from '../const';
import { MANAGE_PROVIDERS } from '../../../constants/routes';

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const AddSocialProvider = ({ type }) => {
  const [socialEmail, setSocialEmail] = useState(false);

  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const signInWith = async () => {
    const provider =
      type === GOOGLE_PROVIDER ? googleProvider : facebookProvider;
    if (authUser) {
      await linkWithPopup(authUser, provider)
        .then((result) => {
          dispatch(onSetAuthUser(result?.user));
          const { email } = result?.user?.providerData?.filter(
            (provider) => provider.providerId === type
          )[0];
          setSocialEmail(email);
          console.log(`${type} account linked (email: ${email})`);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          dispatch(
            setErrorPage(
              'Error',
              error.message,
              'error',
              false,
              'Close',
              MANAGE_PROVIDERS
            )
          );
        });
    } else {
      console.log('User object is not available yet');
    }
  };

  return socialEmail ? (
    <Provider email={socialEmail} providerId={type} />
  ) : (
    <li>
      <a onClick={signInWith}>{'Connect ' + type}</a>
    </li>
  );
};

export default AddSocialProvider;
