import store from '../store';
import { VASegmentTracking } from '@wesalute/lib-segmentio-js';

const ANONYMOUS = 'anonymous';

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * Helper to get Redux data.
 *
 * @return {{brandName: (string|*), memberId: (*|string)}}
 */
const getStateData = () => {
  // Get redux store state.
  const state = store.getState();
  let brandData = state.brandState.data;
  let brandName = isEmpty(brandData) ? '' : brandData.data().label;
  let accountData = state.accountState.account;
  const memberId =
    accountData && accountData.memberId ? accountData.memberId : ANONYMOUS;
  return {
    brandName,
    memberId,
  };
};

/**
 * Common init method.
 *
 * @returns {null}
 */
const init = () => {
  const { brandName, memberId } = getStateData();
  return new VASegmentTracking(brandName, 'Auth App', memberId);
};

/**
 * Segment load helper.
 *
 * @returns {*}
 */
export const load = (segmentKey) => {
  return VASegmentTracking.load(segmentKey);
};

/**
 * Helper wrapper for common Segment page() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.page}
 */
export const page = (name, properties) => {
  const vaAnalytics = init();
  return vaAnalytics.page(name, properties);
};

/**
 * Helper wrapper for common Segment track() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.track}
 */
export const track = (name, properties) => {
  const vaAnalytics = init();
  if (!properties?.['userId']) {
    const { memberId } = getStateData();
    properties['userId'] = memberId !== ANONYMOUS ? memberId : null;
  }
  return vaAnalytics.track(name, properties);
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const identify = () => {
  const vaAnalytics = init();
  return vaAnalytics.identify();
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const reset = () => {
  return VASegmentTracking.reset();
};
