import { useDispatch } from 'react-redux';
import { db, auth, analytics } from '../../../actions';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { Alert, AlertTitle } from '@mui/material';
import reactStringReplace from 'react-string-replace';
import Link from '@mui/material/Link';
import * as routes from '../../../constants/routes';

const styleOverride = {};

const ContactUs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultAlertStyles = useSelector(
    (state) => state.sessionDBState.data.defaultAlertStyles
  );
  const staticTexts = useSelector((state) => state.textsState.data);
  const customStyles = { ...defaultAlertStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const errorPageDataAlert = useSelector((state) => state.errorState.alert);
  const errorPageDataDescTitle = useSelector(
    (state) => state.errorState.descTitle
  );
  const errorPageDataDesc = useSelector((state) => state.errorState.desc);
  const errorPageDataBtn = useSelector((state) => state.errorState.btn);

  const [open, setOpen] = useState(true);

  let errorTitle = errorPageDataAlert.title
    ? errorPageDataAlert.title
    : staticTexts.ErrorDefaultTitle;

  useEffect(() => {
    analytics.page('Error Alert - ' + errorTitle);

    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    setOpen(false);
    dispatch(db.clearErrorPage());
  };

  const clickBtn = () => {
    if (errorPageDataBtn.page) {
      if (errorPageDataBtn.signout) {
        auth.doSignOut().then(() => history.push(errorPageDataBtn.page));
      } else {
        history.push(errorPageDataBtn.page);
      }
      handleClose();
    } else if (errorPageDataBtn.external) {
      window.open(errorPageDataBtn.external, '_blank');
      handleClose();
    } else if (errorPageDataBtn.windowClose) {
      window.close();
      handleClose();
    } else {
      handleClose();
    }
  };

  const signLink = (e, route) => {
    e.preventDefault();

    if (errorPageDataBtn.signout) {
      auth.doSignOut().then(() => {
        handleClose();
        history.push(route);
      });
    } else {
      handleClose();
      history.push(route);
    }
  };

  let alertDesc;

  if (errorPageDataAlert.desc) {
    alertDesc = reactStringReplace(
      errorPageDataAlert.desc,
      '@signin',
      (match, i) => (
        <Link
          key={i}
          href={'#'}
          onClick={(e) => signLink(e, routes.SIGN_IN)}
          className={classes.alertDescLink}
        >
          {staticTexts.SignIn}
        </Link>
      )
    );

    alertDesc = reactStringReplace(alertDesc, '@signup', (match, i) => (
      <Link
        key={i * 55}
        href={'#'}
        onClick={(e) => signLink(e, routes.SIGN_UP)}
        className={classes.alertDescLink}
      >
        {staticTexts.SignUp}
      </Link>
    ));

    alertDesc = reactStringReplace(alertDesc, '@contact', (match, i) => (
      <Link
        key={i * 155}
        href={staticTexts.ContactUsLink}
        target={'_blank'}
        className={classes.alertDescLink}
      >
        {staticTexts.ContactUs}
      </Link>
    ));
  }

  let desc;

  if (errorPageDataDesc) {
    desc = reactStringReplace(errorPageDataDesc, '@signin', (match, i) => (
      <Link
        key={i * 333}
        href={'#'}
        onClick={(e) => signLink(e, routes.SIGN_IN)}
        className={classes.alertDescLink}
      >
        {staticTexts.SignIn}
      </Link>
    ));

    desc = reactStringReplace(desc, '@signup', (match, i) => (
      <Link
        key={i * 101}
        href={'#'}
        onClick={(e) => signLink(e, routes.SIGN_UP)}
        className={classes.alertDescLink}
      >
        {staticTexts.SignUp}
      </Link>
    ));

    desc = reactStringReplace(desc, '@contact', (match, i) => (
      <Link
        key={i * 202}
        href={staticTexts.ContactUsLink}
        target={'_blank'}
        className={classes.alertDescLink}
      >
        {staticTexts.ContactUs}
      </Link>
    ));

    desc = reactStringReplace(desc, '@chrome', (match, i) => (
      <Link
        key={i * 303}
        href={'https://www.google.com/chrome/'}
        target={'_blank'}
        className={classes.alertDescLink}
      >
        {'Google Chrome'}
      </Link>
    ));

    desc = reactStringReplace(desc, '@safari', (match, i) => (
      <Link
        key={i * 505}
        href={'https://www.apple.com/safari/'}
        target={'_blank'}
        className={classes.alertDescLink}
      >
        {'Apple Safari'}
      </Link>
    ));

    desc = reactStringReplace(desc, '@mozilla', (match, i) => (
      <Link
        key={i * 404}
        href={'https://www.mozilla.org/en-US/firefox/'}
        target={'_blank'}
        className={classes.alertDescLink}
      >
        {'Mozilla Firefox'}
      </Link>
    ));
  }

  return (
    <Dialog
      onClose={errorPageDataBtn.close ? handleClose : null}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.container}>
        {!errorPageDataDescTitle && (
          <Alert
            severity={
              errorPageDataAlert.type ? errorPageDataAlert.type : 'error'
            }
            className={classes.alert}
            color={
              errorPageDataAlert.type === 'info'
                ? 'warning'
                : errorPageDataAlert.type
            }
          >
            <AlertTitle className={classes.alertTitle}>{errorTitle}</AlertTitle>
            {errorPageDataAlert.desc && (
              <Typography gutterBottom>{alertDesc}</Typography>
            )}
          </Alert>
        )}
        {errorPageDataDescTitle && (
          <Typography
            gutterBottom
            variant="h2"
            className={classes.title}
            component="h2"
          >
            {errorPageDataDescTitle}
          </Typography>
        )}
        {errorPageDataDesc && (
          <Typography gutterBottom className={classes.desc}>
            {desc}
          </Typography>
        )}
        {errorPageDataBtn.title && (
          <Button
            onClick={clickBtn}
            className={classes.button}
            color="primary"
            type="submit"
            disableElevation
            fullWidth
            variant="contained"
            data-id="conactus-error-button"
            size="large"
          >
            {errorPageDataBtn.title}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default ContactUs;
