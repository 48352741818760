import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';
import Typography from '@mui/material/Typography';
import * as consumerRoutes from '../../../constants/routes';
import { getAppUrl } from '../../../actions/helpers';
import { GOOGLE_PROVIDER } from '../const';
import { APPS_DOMAIN_OLD } from '../../../constants/const';

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getRedirectResult,
  linkWithRedirect,
  getAuth,
} from 'firebase/auth';
import ContainerBox from 'components/Common/ContainerBox';

const auth = getAuth();

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const styleOverride = {};
const AddSocialDeepLink = ({ type }) => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [socialEmail, setSocialEmail] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const provider = type === GOOGLE_PROVIDER ? googleProvider : facebookProvider;
  const providerTitle = type === GOOGLE_PROVIDER ? 'Google' : 'Facebook';

  const authUser = useSelector((state) => state.sessionState.authUser);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  useEffect(async () => {
    if (!authUser) {
      history.push(consumerRoutes.SIGN_IN);
    }
    getSocialResult().then((result) => {
      if (!result) {
        if (authUser?.providerData) {
          // Load auth providers.
          const providerData = authUser.providerData.filter((obj) => {
            return obj.providerId === type;
          });
          if (providerData?.[0]) {
            setProviderData(providerData[0]);
          } else {
            addSocialProvider(authUser, provider);
          }
        } else {
          addSocialProvider(authUser, provider);
        }
      }
    });

    // eslint-disable-next-line
	}, [authUser])

  const addSocialProvider = async (authUser, provider) => {
    if (authUser) {
      await linkWithRedirect(authUser, provider).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
    } else {
      const message = 'User object is not available yet';
      Sentry.captureException(message);
      console.log(message);
    }
  };

  const getSocialResult = async () => {
    return getRedirectResult(auth)
      .then((result) => {
        if (!result) {
          return false;
        }
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          // Accounts successfully linked.
          const { email } = result?.user?.providerData?.filter(
            (provider) => provider.providerId === type
          )[0];
          setSocialEmail(email);
          if (window.opener) {
            const accountUrl =
              getAppUrl('account') ||
              `https://account-local.${APPS_DOMAIN_OLD}`;
            window.opener.postMessage(
              { provider: `${type} account linked (email: ${email})` },
              accountUrl
            );
            window.close();
          }

          return email;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(error.message);
        return error;
      });
  };

  return (
    <ContainerBox>
      {error ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {error}
        </Typography>
      ) : socialEmail ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {providerTitle} provider has been added (email: {socialEmail})
        </Typography>
      ) : providerData ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {providerTitle} provider already exists for email:{' '}
          {providerData?.email}
        </Typography>
      ) : (
        <>
          <Typography variant="body2" className={classes.title} component="h1">
            Please wait until redirected to {providerTitle} provider screen
          </Typography>
        </>
      )}
    </ContainerBox>
  );
};

export default AddSocialDeepLink;
