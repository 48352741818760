import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';

import Preloader from '../../Preloader';
import * as routes from '../../../constants/routes';

import { analytics, api, db, getCustomRoute, helpers } from '../../../actions';

const styleOverride = {
  quote: {
    textAlign: 'left',
    fontFamily: 'monospace',
    fontSize: '0.75em',
    padding: '10px',
    background: '#f1f1f1',
    border: '1px dashed #e12120',
    margin: '10px 0 20px',
  },
};
const initialTimeLeft = 59;

const EmailVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);
  const return_to = useSelector(
    (state) => state.sessionDBState.data.urlQuery.return_to
  );
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [counter, setCounter] = useState(initialTimeLeft);
  const [counterText, setCounterText] = useState(initialTimeLeft);
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);

  const { setErrorPage } = db;

  useEffect(() => {
    if (accountData?.memberId) {
      const timerId = setTimeout(() => {
        // After half a second send the email. This prevents multiple sending of email if the component is called multiple times.
        if (
          !confirmationEmailSent &&
          window.localStorage.getItem('VERIFICATION_EMAIL_SENDING') !==
            authUser.uid
        ) {
          doSendConfirmationEmail();
        } else {
          startTimer();
          setConfirmationEmailSent(true);
        }
      }, 300);

      return () => {
        clearTimeout(timerId);
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [accountData])

  useEffect(() => {
    if (!authUser) {
      getCustomRoute(history);
    }
  }, [authUser]);

  useEffect(() => {
    if (!accountData?.emailVerified) {
      if (!confirmationEmailSent && !authUser?.emailVerified) {
        setLoading(false);
      }
      // If there is no emailVerified field in veterans doc, but the email was verified,
      // add the field, so platform can sync. Usually for google provider's accounts.
      else if (authUser?.emailVerified) {
        helpers.addAccountInfo(
          {
            emailVerified: !!authUser.emailVerified,
          },
          authUser.uid
        );
      }
    } else if (accountData?.emailVerified) {
      dispatch(db.setNotification(staticTexts.VerifyEmailSuccessTitle, true));
      getCustomRoute(history);
    }
  }, [confirmationEmailSent, accountData]);

  const doSendConfirmationEmail = async () => {
    // Set local storage sent.
    window.localStorage.setItem('VERIFICATION_EMAIL_SENDING', authUser.uid);
    const token = await authUser.getIdToken();
    api
      .sendEmailVerification(authUser.email, token, return_to)
      .then(() => {
        analytics.track('Email Verification Started', {
          email: authUser.email,
        });
        setConfirmationEmailSent(true);
        resetTimer();
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(
          setErrorPage(
            staticTexts.GeneralErrorTitle,
            error.message,
            'error',
            false,
            staticTexts.HHMembersWidgetAlertBtn,
            routes.SIGN_IN,
            false,
            false,
            true
          )
        );
      });
  };

  const startTimer = () => {
    let timeLeft = initialTimeLeft;

    setTimer(
      setInterval(() => {
        if (timeLeft > 0) {
          timeLeft--;
          setCounter(timeLeft);
          setCounterText(
            staticTexts.VerifyEmailBtnResendCount.replace('@counter', timeLeft)
          );
        } else {
          stopTimer();
        }
      }, 1000)
    );
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const resetTimer = () => {
    setCounter(initialTimeLeft);
    clearInterval(timer);
    startTimer();
  };

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <React.Fragment>
        {confirmationEmailSent && authUser?.email && (
          <Box
            p={{ xs: 2, md: 10 }}
            boxShadow={{ xs: 0, sm: 0, md: 6 }}
            className={classes.box}
          >
            <Typography variant="h1" className={classes.title} component="h1">
              {staticTexts.VerifyEmailSentTitle}
            </Typography>

            <Typography
              variant="body2"
              className={classes.description}
              component="h2"
            >
              {staticTexts.VerifyEmailSentDesc}
            </Typography>

            <Typography variant="body2" component="div">
              <b>{staticTexts.VerifyEmailSentTitleAbove}</b>
            </Typography>
            <Typography variant="body2" className={classes.quote} component="p">
              To:{' '}
              <i>
                <b>{authUser.email}</b>
              </i>
              <br />
              From:{' '}
              <i>
                <b>{staticTexts.VerifyEmailSentFrom}</b>
              </i>
              <br />
              Title:{' '}
              <i>
                <b>{staticTexts.VerifyEmailSentEmailTitle}</b>
              </i>
            </Typography>

            <Button
              className={classes.button}
              onClick={doSendConfirmationEmail}
              variant={counter !== 0 ? 'outlined' : 'contained'}
              color={counter !== 0 ? 'secondary' : 'primary'}
              disableElevation
              fullWidth
              disabled={counter !== 0}
              data-id="email-verification-button"
              size="large"
            >
              <EmailIcon className={classes.icon} />
              {counter !== 0 && <React.Fragment>{counterText}</React.Fragment>}
              {counter === 0 && (
                <React.Fragment>
                  {staticTexts.VerifyEmailBtnResend}
                </React.Fragment>
              )}
            </Button>

            <Typography
              variant="subtitle2"
              component="div"
              className={classes.terms}
            >
              {staticTexts.VerifyEmailDisclaimer}
            </Typography>
          </Box>
        )}
        {!confirmationEmailSent && (
          <Preloader
            title={
              staticTexts.VerifyEmailSentLoading ??
              'Sending verification email...'
            }
          />
        )}
      </React.Fragment>
    );
  }
};

export default EmailVerification;
