import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import Cookies from 'js-cookie';

import {
  APPS_DOMAIN,
  APPS_DOMAIN_OLD,
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_NAME_OLD,
} from '../../../constants/const';
import Preloader from '../../Preloader';
import * as routes from '../../../constants/routes';

import {
  analytics,
  auth,
  db,
  getCustomRoute,
  helpers,
  identification,
} from '../../../actions';

/**
 * This page confirms that the user is logged in, and writes their session
 * cookie. It should probably be called "doLogin" or something similar.
 */
const Verify = (props) => {
  const { hydraChallenge } = props;
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const configData = useSelector((state) => state.configState.config);
  const accountData = useSelector((state) => state.accountState.account);
  const staticTexts = useSelector((state) => state.textsState.data);
  const publicComputer = useSelector(
    (state) => state.sessionDBState.data.publicComputer
  );
  const hash = useSelector((state) => state.sessionDBState.data.hash);
  const dispatch = useDispatch();
  const [initiate, setInitiate] = useState(false);

  const { setErrorPage } = db;

  useEffect(() => {
    analytics.page('Verify');
    analytics.identify();
    // eslint-disable-next-line
  }, [])

  /**
   * Move Hydra redirect upper for better performance.
   * @see CX-2789.
   */
  useEffect(() => {
    if (hydraChallenge) {
      helpers
        .doHydraAction({
          search: hydraChallenge,
          memberId: accountData.memberId,
          action: 'redirect',
        })
        .then((redirect) => {
          if (redirect?.data) {
            window.location.href = redirect.data;
          } else {
            throw Error('Error getting redirect');
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error('hydra', error);

          dispatch(
            setErrorPage(
              staticTexts.ErrorDefaultTitle,
              error.message,
              'error',
              false,
              staticTexts.ErrorDefaultButton,
              routes.SIGN_IN
            )
          );
        });
    }
  }, []);

  useEffect(() => {
    if (!authUser) {
      getCustomRoute(history);
    } else {
      const finishLogin = () => {
        const { returnType, returnUrl, providerToken, sessionDbId } = props;
        setInitiate(true);

        if (returnType === 'widget') {
          helpers
            .writeSession(
              {
                session_id: sessionDbId,
                user_uid: authUser.uid,
                email: accountData.email,
                memberId: accountData.memberId,
                firstname: accountData.firstname,
                lastname: accountData.lastname,
                phone: accountData.phone ? accountData.phone : null,
                last_update: new Date(),
              },
              sessionDbId
            )
            .then(() => {
              let returnUrlArgs = returnUrl ? returnUrl.split('?') : [];
              let compiledReturnUrl =
                returnUrl +
                '?providerToken=' +
                providerToken +
                '&sessionId=' +
                sessionDbId +
                '&version=latest&xcomponent=1';

              if (
                returnUrlArgs &&
                returnUrlArgs.length > 1 &&
                returnUrlArgs[1] &&
                returnUrlArgs[1] !== ''
              ) {
                // Parameters exists, so join the arguments.
                compiledReturnUrl =
                  returnUrl +
                  '&providerToken=' +
                  providerToken +
                  '&sessionId=' +
                  sessionDbId +
                  '&version=latest&xcomponent=1';
              }

              // If verify integration of milvetId is set, redirect to verify app.
              if (
                !isCheckoutUrl(returnUrl) &&
                !identification.isFullyVerified(accountData)
              ) {
                compiledReturnUrl =
                  helpers.getAppUrl('verify') +
                  '?sessionId=' +
                  sessionDbId +
                  '&return_to=' +
                  encodeURIComponent(compiledReturnUrl) +
                  '&providerToken=' +
                  providerToken +
                  '&publicComputer=' +
                  publicComputer;
              }

              return setPlatformToken(compiledReturnUrl);
            })
            .catch((error) => {
              Sentry.captureException(error);
              console.error('verify', error);

              dispatch(
                setErrorPage(
                  staticTexts.ErrorDefaultTitle,
                  error.message,
                  'error',
                  false,
                  staticTexts.ErrorDefaultButton,
                  routes.SIGN_IN
                )
              );
            });
        } else if (returnType === 'verify') {
          helpers
            .writeSession(
              {
                session_id: sessionDbId,
                user_uid: authUser.uid,
                email: accountData.email,
                memberId: accountData.memberId,
                firstname: accountData.firstname,
                lastname: accountData.lastname,
                phone: accountData.phone ? accountData.phone : null,
                last_update: new Date(),
              },
              sessionDbId
            )
            .then(() => {
              const redirectUrl = hash ? returnUrl + hash : returnUrl;
              return setPlatformToken(redirectUrl);
            })
            .catch((error) => {
              Sentry.captureException(error);
              console.error('verify', error);

              dispatch(
                setErrorPage(
                  staticTexts.ErrorDefaultTitle,
                  error.message,
                  'error',
                  false,
                  staticTexts.ErrorDefaultButton,
                  routes.SIGN_IN
                )
              );
            });
        } else {
          let returnUrlData;
          if (!configData?.platformUrl && !returnUrl) {
            // If no platform and redirect URL then use marketplace.
            returnUrlData =
              configData?.platformMarketplaceUrl ??
              `https://www.${window.location.hostname.endsWith(APPS_DOMAIN_OLD) ? APPS_DOMAIN_OLD : APPS_DOMAIN}/marketplace`;
          } else {
            returnUrlData = returnUrl ? returnUrl : configData.platformUrl;
            // Override default URL if returnUrl is equal to platform URL.
            if (returnUrl === configData?.platformUrl) {
              returnUrlData = configData.platformMarketplaceUrl;
            }
          }

          setPlatformToken(returnUrlData);
        }
      };

      const setPlatformToken = async (returnUrlData) => {
        const csrf = await helpers.doGetCsrfToken();
        const url = auth.getSessionCookieUrl();
        const options = {
          method: 'post',
          url: url,
          data: {
            token: authUser?.refreshToken,
            csrfToken: csrf?.data?.csrfToken,
            key: process.env.REACT_APP_API_KEY,
          },
        };
        return axios(options)
          .then((res) => {
            const { sessionDbId } = props;
            // Save token in db.
            if (res?.data?.token) {
              if (
                process.env.REACT_APP_ENV === 'local-dev' ||
                process.env.REACT_APP_ENV === 'emulator'
              ) {
                // TODO delete once new domain applied.
                // Set cookies for local development old domain.
                const optionsOld = {
                  expires: 1,
                  path: '/',
                  domain: `.${APPS_DOMAIN_OLD}`,
                };
                Cookies.set(AUTH_COOKIE_NAME_OLD, res?.data?.token, optionsOld);
                Cookies.set(AUTH_COOKIE_NAME, res?.data?.token, optionsOld);
                // Set cookies for local development new domain.
                const options = {
                  expires: 1,
                  path: '/',
                  domain: `.${APPS_DOMAIN}`,
                };
                Cookies.set(AUTH_COOKIE_NAME_OLD, res?.data?.token, options);
                Cookies.set(AUTH_COOKIE_NAME, res?.data?.token, options);
              }
              if (sessionDbId) {
                const updates = {
                  loginToken: res?.data?.token,
                };
                return helpers.writeLoginSession(updates, sessionDbId);
              }
            }
            return false;
          })
          .then(() => {
            // Record user's auth entry before leaving last AuthApp page.
            return helpers.doUpdateAuthRecord();
          })
          .then(() => {
            return helpers.setLastLogin(authUser);
          })
          .then(() => {
            window.location.href = returnUrlData;
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.error('verify', error);
            dispatch(
              setErrorPage(
                staticTexts.ErrorDefaultTitle,
                error.message,
                'error',
                false,
                staticTexts.ErrorDefaultButton,
                routes.SIGN_IN
              )
            );
          });
      };

      /**
       * Help to detect Checkout app from returnUrl parameter.
       *
       * @param string
       * @returns {boolean}
       */
      const isCheckoutUrl = (string) => {
        let result = false;
        try {
          let url = new URL(string);
          const matcher = new RegExp(
            'https:\\/\\/checkout.*' +
              APPS_DOMAIN +
              '|https:\\/\\/checkout.*' +
              APPS_DOMAIN_OLD
          );
          if (matcher.test(url.href)) {
            result = true;
          }
        } catch (e) {}
        return result;
      };

      if (!initiate && !hydraChallenge) {
        finishLogin();
      }
    }
    // eslint-disable-next-line
  }, [initiate, accountData, authUser, configData, staticTexts])

  return (
    <div>
      <Preloader title={staticTexts.VerificationPreloaderTitle} />
    </div>
  );
};

export default Verify;
