import React from 'react-redux';
import * as constants from './PrivacyConstants';

/**
 * Shows the full text of each privacy document in tabs
 */
const PrivacyView = ({ privacy, navigate, tab, styles, header, buttons }) => {
  if (privacy) {
    const { terms_of_service, privacy_policy } = privacy;

    return (
      <>
        {header}
        <div className={styles.tabs}>
          <div
            onClick={navigate.viewTermsFull}
            className={`${styles.tab} ${styles.first} ${tab === constants.TERMS_OF_SERVICE ? styles.active : styles.inactive}`}
          >
            Terms of Service
          </div>
          <div
            onClick={navigate.viewPrivacyPolicyFull}
            className={`${styles.tab} ${styles.first} ${tab === constants.PRIVACY_POLICY ? styles.active : styles.inactive}`}
          >
            Privacy Policy
          </div>
        </div>
        <div className={`${styles.scroller} ${styles.short}`}>
          <a
            className={styles.back}
            href="/privacy"
            onClick={navigate.viewSummary}
          >
            &lt; Back to summary
          </a>
          {tab === constants.TERMS_OF_SERVICE &&
          terms_of_service &&
          terms_of_service.fullText ? (
            <>
              <div className={styles.versionNumber}>
                Version {terms_of_service.versionNumber}.0
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: privacy.terms_of_service.fullText,
                }}
              />
            </>
          ) : null}

          {tab === constants.PRIVACY_POLICY &&
          privacy_policy &&
          privacy_policy.fullText ? (
            <>
              <div className={styles.versionNumber}>
                Version {privacy_policy.versionNumber}.0
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: privacy.privacy_policy.fullText,
                }}
              />
            </>
          ) : null}
        </div>
        {buttons}
      </>
    );
  } else {
    return null;
  }
};

export default PrivacyView;
