import { analytics } from '../../../actions';
import React, { useEffect } from 'react';
import ContainerBox from 'components/Common/ContainerBox';
import { IconButton, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';

const styleOverride = {};

const Page404 = () => {
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const staticTexts = useSelector((state) => state.textsState.data);

  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    analytics.page('Error Page 404 - ' + window.location?.href);

    // eslint-disable-next-line
  }, [])

  const signLink = (e, route) => {
    e.preventDefault();
    history.push(route);
  };

  let continueText = reactStringReplace(
    staticTexts.Page404Links ??
      `Feel free to either @signUp if you're new, or @signIn if you already have an account, and then give it another shot.`,
    '@signup',
    (match, i) => (
      <Link
        key={`continueText-signup-${i}`}
        href={routes.SIGN_UP}
        onClick={(e) => signLink(e, routes.SIGN_UP)}
        className={classes.link}
      >
        {staticTexts.SignUp}
      </Link>
    )
  );

  continueText = reactStringReplace(continueText, '@signIn', (match, i) => (
    <Link
      key={`continueText-signin-${i}`}
      href={routes.SIGN_IN}
      onClick={(e) => signLink(e, routes.SIGN_IN)}
      className={classes.link}
    >
      {staticTexts.SignIn}
    </Link>
  ));

  return (
    <ContainerBox>
      <IconButton
        aria-label="back"
        className={classes.back}
        onClick={() => history.push(routes.SIGN_UP)}
      >
        <ArrowBackIosIcon fontSize="medium" />
      </IconButton>
      <Typography variant="h1" className={classes.title} component="h1">
        {staticTexts.Page404Title ?? 'Page not found'}
      </Typography>
      <Typography
        variant="body2"
        className={classes.description}
        component="h2"
      >
        {staticTexts.Page404Description ??
          'This page does not exist or is not accessible.'}
      </Typography>

      <Typography variant="body2" component="div">
        {continueText}
      </Typography>
    </ContainerBox>
  );
};

export default Page404;
