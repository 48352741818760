import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import * as routes from '../../../../constants/routes';
import ContainerBox from 'components/Common/ContainerBox';

const styleOverride = {
  subtitle2: {
    fontWeight: 400,
    color: '#677796',
    lineHeight: '12px',
    marginTop: '30px',
  },
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '25px',
    marginBottom: '25px',
  },
};

const PasswordForgetForm = (props) => {
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const staticTexts = useSelector((state) => state.textsState.data);
  const customStyles = { ...defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  const history = useHistory();

  const {
    onChangeInput,
    onSubmit,
    sendEmail,
    email,
    inputValidation,
    fieldsError,
    isInvalid,
    error,
  } = props;

  const url = window.location.href;
  const params = new URL(url).searchParams;
  const expired = params.get('expired');
  const reverifyEmail = params.get('reverify_email');
  const title = expired
    ? staticTexts.PasswordForgetExpiredTitle
    : staticTexts.PasswordForgetTitle;
  const description = expired
    ? staticTexts.PasswordForgetExpiredDescription
    : staticTexts.PasswordForgetDescription;
  const buttonText = expired
    ? staticTexts.PasswordForgetExpiredButton
    : staticTexts.PasswordForgetButton;
  const footerText =
    staticTexts.PasswordForgetExpiredFooter ??
    'If the email does not appear in your in box, please check your Junk/Spam folder.';

  useEffect(() => {
    if (reverifyEmail) {
      // Try to decode given reverify_email.
      try {
        const decodedEmail = decodeURIComponent(reverifyEmail);
        sendEmail(decodedEmail);
      } catch (e) {
        console.error('Error decoding given email', reverifyEmail);
      }
    }
  }, [reverifyEmail]);

  return (
    <ContainerBox>
      <IconButton
        aria-label="back"
        className={classes.back}
        onClick={() => history.push(routes.SIGN_IN_EMAIL)}
      >
        <ArrowBackIosIcon fontSize="medium" />
      </IconButton>
      <Typography variant="h1" className={classes.title} component="h1">
        {title}
      </Typography>

      <Typography
        variant="body2"
        className={classes.description}
        component="h2"
      >
        {description}
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <FormControl
          error={!!fieldsError.email || error}
          variant="filled"
          fullWidth
        >
          <InputLabel htmlFor="email">{staticTexts.Email}</InputLabel>
          <FilledInput
            id="email"
            className={classes.field}
            value={email}
            data-id="password-forget-email"
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.Email, true, 'email');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Email, true, 'email');
            }}
          />
          {(error || fieldsError.email) && (
            <FormHelperText className={classes.marginHelper}>
              {error ? error.message : fieldsError.email}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          color="primary"
          type="submit"
          disableElevation
          fullWidth
          data-id="password-forget-button"
          size="large"
        >
          {buttonText}
        </Button>

        <Typography variant="body2" component="div">
          {!expired && (
            <Link
              href={'#'}
              className={classes.link}
              onClick={(e) => {
                e.preventDefault();
                history.push(routes.SIGN_IN);
              }}
              data-id="return-to-login-link"
            >
              {staticTexts.PasswordForgetSignInLink}
            </Link>
          )}
          {expired && (
            <Typography variant="subtitle2" className={classes.subtitle2}>
              {footerText}
            </Typography>
          )}
        </Typography>
      </form>
    </ContainerBox>
  );
};

export default PasswordForgetForm;
