import React from 'react';
import { useSelector } from 'react-redux';
import { useWesaluteRecaptcha } from '../../../hooks';
import Preloader from '../../Preloader';
import EmailConfirmForm from './EmailConfirmForm';

const EmailConfirm = () => {
  const [recaptcha] = useWesaluteRecaptcha('email_confirm');
  const staticTexts = useSelector((state) => state.textsState.data);

  if (!recaptcha) {
    return <Preloader title={staticTexts.NewPasswordLoading} />;
  } else {
    return <EmailConfirmForm />;
  }
};

export default EmailConfirm;
