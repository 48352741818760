import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';
import * as routes from './../../../constants/routes';

const SignInWithFacebook = (props) => {
  const styleChanges = {
    icon: {
      ...props.style.icon,
      color: '#4460A0',
    },
  };

  const useStyles = makeStyles(() => ({
    ...props.style,
    ...styleChanges,
  }));
  const classes = useStyles();

  const history = useHistory();

  const urlQuery = useSelector((state) => state.sessionDBState.data.urlQuery);
  const staticTexts = useSelector((state) => state.textsState.data);

  const signInWithFacebook = () => {
    const params = new URLSearchParams(urlQuery);
    history.push({
      pathname: routes.SIGNUP_WITH_REDIRECT.replace(':provider', 'facebook'),
      search: params.toString(),
    });
  };

  return (
    <Button
      className={classes.button}
      onClick={() => signInWithFacebook()}
      variant={'outlined'}
      color="secondary"
      disableElevation
      fullWidth
      data-id="signin-facebook-button"
      size="large"
    >
      <FacebookIcon className={classes.icon} />
      {props.text ? props.text : staticTexts.FacebookSignUp}
    </Button>
  );
};

export default SignInWithFacebook;
