import { analytics } from '../../../actions';
import React, { useEffect } from 'react';

const Page401 = () => {
  useEffect(() => {
    analytics.page('Error Page 401. Revoked user. - ' + window.location?.href);
    // eslint-disable-next-line
  }, [])

  return <></>;
};

export default Page401;
