import { UnleashCustomClient } from '@wesalute/lib-unleash-client-js';
import { analytics } from './index';

const unleashSettings = {
  url: process.env.REACT_APP_UNLEASH_API_URL,
  clientKey: process.env.REACT_APP_UNLEASH_API_KEY,
  appName: 'Auth App',
  refreshInterval: 100,
  environment: process.env.REACT_APP_ENV,
};

const getInstance = async (memberId, additionalProperties) => {
  const unleashClient = new UnleashCustomClient(
    memberId,
    analytics,
    {
      context: {
        app: {
          name: 'AuthApp',
          namespace: 'components/Verification/Verify',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
    },
    unleashSettings,
    additionalProperties
  );
  await unleashClient.initUnleash();

  return unleashClient;
};

export { getInstance };
