import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import { ReactComponent as GoogleIcon } from './google-icon.svg';

import * as routes from '../../../constants/routes';

const SignInWithGoogle = (props) => {
  const styleChanges = {
    icon: {
      ...props.style.icon,
      padding: '3px',
    },
  };
  const useStyles = makeStyles(() => ({
    ...props.style,
    ...styleChanges,
  }));
  const classes = useStyles();

  const history = useHistory();
  const urlQuery = useSelector((state) => state.sessionDBState.data.urlQuery);
  const staticTexts = useSelector((state) => state.textsState.data);

  const signInWithGoogle = async () => {
    const params = new URLSearchParams(urlQuery);
    history.push({
      pathname: routes.SIGNUP_WITH_REDIRECT.replace(':provider', 'google'),
      search: params.toString(),
    });
  };

  return (
    <Button
      className={classes.button}
      onClick={() => signInWithGoogle()}
      variant={'outlined'}
      color="secondary"
      disableElevation
      fullWidth
      data-id="signin-google-button"
      size="large"
    >
      <SvgIcon component={GoogleIcon} className={classes.icon} />
      {props.text ? props.text : staticTexts.GoogleSignUp}
    </Button>
  );
};

export default SignInWithGoogle;
