import axios from 'axios';

/**
 * Helper to build axios config object.
 *
 * @param method
 * @param url
 * @param data
 * @param token
 * @param withCredentials
 * @return {{headers: {"Content-Type": string}, method, data: string, url: string}}
 */
const axiosConfigBuilder = (
  method,
  url,
  data,
  token = null,
  withCredentials = false
) => {
  const config = {
    method: method,
    url: process.env.REACT_APP_VA_API_URL + url,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Add data if needed.
  if (data) {
    config.data = JSON.stringify(data);
  }

  // Add credentials when needed.
  if (withCredentials) {
    config.withCredentials = true;
  }

  // Add bearer if needed.
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

/**
 * Helper to send password reset email again.
 *
 * @param token
 * @return {Promise<*>}
 */
export const sendPasswordResetEmail = async (token) => {
  const config = axiosConfigBuilder(
    'post',
    '/member/auth/resend/password-reset',
    { token }
  );
  return axios.request(config);
};

/**
 * Helper to send email verification message.
 *
 * @param email
 * @param token
 * @param return_to
 * @param is_new
 * @return {Promise<*>}
 */
export const sendEmailVerification = async (
  email,
  token,
  return_to,
  is_new = true
) => {
  const config = axiosConfigBuilder(
    'post',
    '/member/auth/email/verify',
    { email, return_to, is_new },
    token
  );
  return axios.request(config);
};

/**
 * Email confirmation helper.
 *
 * @param token
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export const confirmEmail = async (token) => {
  const config = axiosConfigBuilder('post', '/member/auth/email/confirm', {
    token,
  });
  return axios.request(config);
};

/**
 * Re-captcha check helper.
 *
 * @param token
 * @param action
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export const recaptchaChecker = async (token, action) => {
  const config = axiosConfigBuilder('post', '/member/auth/recaptcha-verifier', {
    token,
    action,
  });
  return axios.request(config);
};

export const setToken = async (token) => {
  const config = axiosConfigBuilder(
    'post',
    '/auth/session',
    { token },
    null,
    true
  );
  return axios.request(config);
};

export const deleteToken = async () => {
  const config = axiosConfigBuilder(
    'delete',
    '/auth/session',
    null,
    null,
    true
  );
  return axios.request(config);
};

/**
 * Verify email domain helper.
 *
 * @param email
 * @return {Promise<axios.AxiosResponse<any>>}
 */
export const verifyEmailDomain = async (email) => {
  const config = axiosConfigBuilder(
    'post',
    '/member/auth/verify-email-domain',
    { email }
  );
  return axios.request(config);
};
